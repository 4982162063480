import { IonApp, IonButton, IonContent, IonPage, IonPopover, IonProgressBar, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import './App.scss';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

import '@ionic/react/css/palettes/dark.system.css';
import './theme/variables.css';

/* Utilities */
import GivingTuesday from './features/other/GivingTuesday';
import { User } from './models/data';
import { translation } from './models/translation';
import AuthServ from './services/auth';
import { retrieveLanguage, storeLanguage } from './services/language_service';

/* Lazy Load Pages */
const InitPsReset = lazy(() => import('./features/authentication/InitPsReset'));
const NewPs = lazy(() => import('./features/authentication/NewPs'));
const Signin = lazy(() => import('./features/authentication/Signin'));
const Signup = lazy(() => import('./features/authentication/Signup'));
const Dashboard = lazy(() => import('./features/Dashboard'));
const About = lazy(() => import('./features/documents/About'));
const ConfidentialPol = lazy(() => import('./features/documents/ConfidentialPolicy'));
const Faq = lazy(() => import('./features/documents/Faq'));
const LegalNotice = lazy(() => import('./features/documents/LegalNotice'));
const UserTermsP = lazy(() => import('./features/documents/UserTerms'));
const DonateHome = lazy(() => import('./features/donations_and_credit/DonateHome'));
const TransactionFailure = lazy(() => import('./features/donations_and_credit/TransactionFailure'));
const TransactionSuccess = lazy(() => import('./features/donations_and_credit/TransactionSuccess'));
const FundraiserDetail = lazy(() => import('./features/fundraise/FundraiseDetail'));
const FundraiseHome = lazy(() => import('./features/fundraise/FundraiseHome'));
const GiftCardHome = lazy(() => import('./features/giftcards/giftcardshome'));
const Home = lazy(() => import('./features/Home'));
const AllNews = lazy(() => import('./features/news/AllNews'));
const News = lazy(() => import('./features/news/news'));
const NotFoundPage = lazy(() => import('./features/NotFound'));
const Organizations = lazy(() => import('./features/organizations/Organizations'));
const Orgdetail = lazy(() => import('./features/organizations/OrgDetail'));

/* Lazy Load Components */
const Header = lazy(() => import('./components/Header'));
const LogoutTimer = lazy(() => import('./features/authentication/LogoutTimer'));

setupIonicReact();

const App: React.FC = () => {
  let pathname = window.location.pathname;

  const getLan = (pathname: string) => {
    let languages: string[] = ['/de', '/en', '/fr'];
    let siteLanguage = '';

    languages.every((lang) => {
      if (pathname.indexOf(lang) === 0) {
        siteLanguage = lang;
        return false;
      }
      return true;
    });

    if (siteLanguage.length < 1) {
      let localLan = retrieveLanguage();
      if (localLan && localLan.length > 1) {
        siteLanguage = localLan;
      } else {
        siteLanguage = '/fr';
      }
    } else {
      storeLanguage(siteLanguage);
    }

    return siteLanguage;
  };


  let lan=getLan(pathname)

  const siteText = translation();
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [redirection] = useState<{ path: string; state: any }>({ path: '', state: null });
  const [signedIn, setSignedIn] = useState<boolean>(false);

  const signIn = (value: boolean) => {
    setSignedIn(value);
  };

  const authServ = new AuthServ();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState('');

  
  useEffect(() => {
    getUser();
  }, []);

  const handleLogout = async () => {
    await authServ.unSetUser();
    setSignedIn(false);
    removeUser();
    setPopoverOpen(true);
  };

  const getUser = async () => {
    authServ.getUser().then((data) => {
      setUser(data);
      if (data?.email) {
        setSignedIn(true);
      }
      setLoading('done');
    });
  };

  const removeUser = () => {
    setUser(null);
  };

  const refresh = () => {
    getUser();
    setLoading('');
  };

  return (
    <IonApp>
      {user && <LogoutTimer timeout={25 * 60 * 1000} onLogout={handleLogout} />}
      <IonReactRouter>
        {loading === 'done' && (
          <IonPage>
            <Suspense fallback={<IonProgressBar type='indeterminate' color='tertiary'></IonProgressBar>}>
              <Header removeUser={removeUser} signIn={signIn} user={user} />

              <Route exact path="/">
                {redirection.path?.length > 0 ? (
                  <Redirect to={{ pathname: `${lan}/${redirection.path}`, state: redirection.state }} />
                ) : (
                  <Redirect to={`${lan}/home`} />
                )}
              </Route>

              <Route exact path={`${lan}/`}>
                {redirection.path?.length > 0 ? (
                  <Redirect to={{ pathname: `${lan}/${redirection.path}`, state: redirection.state }} />
                ) : (
                  <Redirect to={`${lan}/home`} />
                )}
              </Route>

              {/* Home Page */}
              <Route path={`${lan}/home`}>
                <Home user={user} lan={lan} />
              </Route>

              {/* Account Pages */}
              <Route path={`${lan}/account`}>
                {user ? <Dashboard lan={lan} removeUser={removeUser} refresh={refresh} user={user} signIn={signIn} /> : <Redirect to={`${lan}/home`} />}
              </Route>
              <Route path={`${lan}/account/:`}>
                <Redirect to={`${lan}/account`} />
              </Route>

              {/* Authentication Pages */}
              <Route path={`${lan}/signin`} exact>
                <Signin user={user} refresh={refresh} singIn={signIn} />
              </Route>
              <Route path={`${lan}/signup`} exact>
                <Signup user={user} />
              </Route>
              <Route path={`${lan}/password_reset`} exact>
                <InitPsReset user={user} refresh={refresh} singIn={signIn} />
              </Route>
              <Route path={`${lan}/set_new_password`}>
                <NewPs user={user} refresh={refresh} singIn={signIn} />
              </Route>

              {/* Documents */}
              <Route path={`${lan}/about_us`}>
                {!user ? <About /> : <Redirect to={`${lan}/account/about_us`} />}
              </Route>
              <Route path={`${lan}/a_propos`}>
                {!user ? <About /> : <Redirect to={`${lan}/account/a_propos`} />}
              </Route>
              <Route path={`${lan}/uber_uns`}>
                {!user ? <About /> : <Redirect to={`${lan}/account/uber_uns`} />}
              </Route>

              {/* Not Found Page */}
              <Route path={`${lan}/notfound`}>
                <NotFoundPage />
              </Route>

              {/* Charity Pages */}
              <Route exact path={`${lan}/charities`}>
                <Organizations />
              </Route>
              <Route path={`${lan}/charities/:id`}>
                <Orgdetail user={user} />
              </Route>

              {/* Fundraising and Donations */}
              <Route path={`${lan}/fundraise`}>
                <FundraiseHome user={user} />
              </Route>
              <Route  exact path={`${lan}/giftcard`}>
                {!user ? <GiftCardHome user={user} /> : <Redirect to={`${lan}/account/giftcard`} />}
              </Route>
              <Route  exact path={`${lan}/carte_cadeau`}>
                {!user ? <GiftCardHome user={user} /> : <Redirect to={`${lan}/account/carte_cadeau`} />}
              </Route>
              <Route  exact path={`${lan}/geschenkkarten`}>
                {!user ? <GiftCardHome user={user} /> : <Redirect to={`${lan}/account/geschenkkarten`} />}
              </Route>
              <Route  exact path={`${lan}/donate`}>
                {!user ? <DonateHome user={user} /> : <Redirect to={`${lan}/account/donate`} />}
              </Route>
              <Route  exact path={`${lan}/donner`}>
                {!user ? <DonateHome user={user} /> : <Redirect to={`${lan}/account/donner`} />}
              </Route>
              <Route  exact path={`${lan}/spenden`}>
                {!user ? <DonateHome user={user} /> : <Redirect to={`${lan}/account/spenden`} />}
              </Route>

              {/*GIVINGTUESDAY */}
              <Route exact path={`${lan}/givingtuesdaychallenge`}>
                <GivingTuesday slug={"givingtuesdaychallenge"}></GivingTuesday>
              </Route>
              <Route exact path={`${lan}/givingtuesdaychallenge-fr`}>
                <GivingTuesday slug={"givingtuesdaychallenge-fr"}></GivingTuesday>
              </Route>
              <Route exact path={`${lan}/givingtuesdaychallenge-de`}>
                <GivingTuesday slug={"givingtuesdaychallenge-de"}></GivingTuesday>
              </Route>


              {/* Fundraisers and News */}
              <Route path={`${lan}/fundraisers/:id`}>
                <FundraiserDetail user={user} />
              </Route>
              <Route exact path={`${lan}/articles`}>
                <AllNews user={user} />
              </Route>
              <Route path={`${lan}/articles/:name`}>
                <News user={user} />
              </Route>

              {/* Legal Pages */}
              <Route exact path={`${lan}/user_terms`}>
                <UserTermsP />
              </Route>
              <Route exact path={`${lan}/confidential_policy`}>
                <ConfidentialPol />
              </Route>
              <Route exact path={`${lan}/legal_notice`}>
                <LegalNotice />
              </Route>

              {/* FAQs */}
              <Route path={`${lan}/faq`}>
                <Faq user={user} />
              </Route>

              {/**PAMYMENT CONFIRMATION */}
              <Route path={`${lan}/payment_failure`}>
                <TransactionFailure />
              </Route>
              <Route path={`${lan}/payment_success`}>
                <TransactionSuccess />
              </Route>
            </Suspense>
          </IonPage>
        )}
      </IonReactRouter>
      <IonPopover ref={popover} isOpen={popoverOpen} backdropDismiss={false}>
        <IonContent>
          <div className="ion-padding">
            <h4>{siteText.autologgedOutTitle}</h4>
            <p>{siteText.autologgedOutBody}</p>
            <IonButton onClick={() => setPopoverOpen(false)}>Ok</IonButton>
          </div>
        </IonContent>
      </IonPopover>
    </IonApp>
  );
};

export default App;
